// import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import Lottie from "react-lottie";
// // import animationData from "../loading.json"; // Ensure this path is correct
// import "./AnimationPage.css";
// import { API_ENDPOINTS } from '../config';

// function AnimationPage() {
//   const [steps, setSteps] = useState([false, false, false, false, false]);
//   const [progress, setProgress] = useState(0);
//   const [responseData, setResponseData] = useState(null);
//   const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0); //
//   const navigate = useNavigate();

//   const animations = [
//     "https://lottie.host/3fea76e0-e3e0-49f7-acc9-22d109283b28/wc003s0mwI.json", // First animation URL
//     "https://lottie.host/108ac2ee-a698-41de-bd96-adf405839e07/ZVcciN37eg.json", // Second animation URL
//   ];
//   const location = useLocation();
//   const patientId = location.state?.patientId;
//   const claimId = location.state?.claimId; // Also retrieve claimId from state

//   useEffect(() => {
//     // Ensure both patientId and claimId are available
//     if (!patientId || !claimId) {
//       setError("Patient ID or Claim ID is missing.");
//       console.error("Patient ID or Claim ID is missing");
//       return;
//     }

//     const fetchData = async () => {
//       // const url1 = `${API_ENDPOINTS.PYTHON_SERVICE}/analyze_documents/${patientId}`;
//       const url1 = `${API_ENDPOINTS.PYTHON_SERVICE}/analyze_documents/${patientId}/${claimId}`;
//       const url2 = `${API_ENDPOINTS.PYTHON_SERVICE}/save_response`;

//       console.log("Making POST request to URL 1:", url1);

//       try {
//         const response1 = await fetch(url1, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           // body: JSON.stringify({ patientId }),
//           body: JSON.stringify({ patientId, claimId }),  // Send both patientId and claimId
//         });

//         if (response1.ok) {
//           const data1 = await response1.json();
//           console.log("API call 1 successful:", data1);
//           setSteps((prevSteps) => {
//             const newSteps = [...prevSteps];
//             newSteps[0] = true;
//             newSteps[1] = true; // Mark the second step as completed
//             setProgress(45); // Move to 45% after the first two steps
//             return newSteps;
//           });

//           console.log("Making POST request to URL 2:", url2);

//           const response2 = await fetch(url2, {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify(data1),
//           });

//           if (response2.ok) {
//             const data2 = await response2.json();
//             console.log("API call 2 successful:", data2);
//             handleApiResponse(data2);
//           } else {
//             console.error("Failed to process request 2:", response2.statusText);
//             const errorData = await response2.json();
//             console.error("Error details:", errorData);
//           }
//         } else {
//           console.error("Failed to process request 1:", response1.statusText);
//           const errorData = await response1.json();
//           console.error("Error details:", errorData);
//         }
//       } catch (error) {
//         console.error("Error in fetching data:", error);
//       }
//     };

//     fetchData();
//   }, [patientId]);

//   const handleApiResponse = (data) => {
//     // Process the API response data here
//     setResponseData(data);

//     // Example processing: Log the response data
//     console.log("Processing response data:", data);

//     // Start the animation steps
//     startAnimation();
//   };

//   const startAnimation = () => {
//     let stepIndex = 2; // Start from 2 since the first two steps are already completed
//     const interval = setInterval(() => {
//       setSteps((prevSteps) => {
//         const newSteps = [...prevSteps];
//         newSteps[stepIndex] = true;
//         // Update progress based on step index
//         if (stepIndex === 2) {
//           setProgress(60); // 60% after third step
//         } else if (stepIndex === 3) {
//           setProgress(80); // 80% after fourth step
//         } else if (stepIndex === 4) {
//           setProgress(100); // 100% after fifth step
//         }
//         stepIndex += 1;
//         if (stepIndex === newSteps.length) {
//           clearInterval(interval);
//           setTimeout(() => {
//             navigate("/claim-details", { state: { patientId, responseData } });
//           }, 500);
//         }
//         return newSteps;
//       });
//     }, 1000);
//   };


//   useEffect(() => {
//     const toggleInterval = setInterval(() => {
//       setCurrentAnimationIndex((prevIndex) => (prevIndex + 1) % animations.length);
//     }, 5000); // Switch animation every 5 seconds

//     return () => clearInterval(toggleInterval); // Clear interval on component unmount
//   }, [animations.length]);


//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     path: animations[currentAnimationIndex], // Use the current animation URL
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice",
//     },
//   };

//   return (
//     <div className="animation-page">
//       <header>
//         <h1>Initiating claim review</h1>
//       </header>
//       <div className="lottie-container">
//       <Lottie key={currentAnimationIndex} options={defaultOptions} height={100} width={100} />
//       </div>
//       <div className="progress-bar">
//         <div className="progress" style={{ width: `${progress}%` }}></div>
//       </div>
//       <ul className="animation-list">
//         <li>
//           <span>Verifying member eligibility.....</span>
//           <span className={`checkmark ${steps[0] ? "checked" : ""}`}>✔</span>
//         </li>
//         <li>
//           <span>Analysing policy details.....</span>
//           <span className={`checkmark ${steps[1] ? "checked" : ""}`}>✔</span>
//         </li>
//         <li>
//           <span>Comparing with Standard Treatment Guidelines...</span>
//           <span className={`checkmark ${steps[2] ? "checked" : ""}`}>✔</span>
//         </li>
//         <li>
//           <span>Reviewing Bills, Tariffs, & MOUs...</span>
//           <span className={`checkmark ${steps[3] ? "checked" : ""}`}>✔</span>
//         </li>
//         <li>
//           <span>Detecting Potential Fraud...</span>
//           <span className={`checkmark ${steps[4] ? "checked" : ""}`}>✔</span>
//         </li>
//       </ul>
//     </div>
//   );
// }

// export default AnimationPage;




import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import "./AnimationPage.css";
import { API_ENDPOINTS } from '../config';

function AnimationPage() {
  const [steps, setSteps] = useState([false, false, false, false, false]);
  const [progress, setProgress] = useState(0);
  const [responseData, setResponseData] = useState(null);
  const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0);
  const [error, setError] = useState(null);  // Define error state
  const navigate = useNavigate();

  const animations = [
    "https://lottie.host/3fea76e0-e3e0-49f7-acc9-22d109283b28/wc003s0mwI.json", // First animation URL
    "https://lottie.host/108ac2ee-a698-41de-bd96-adf405839e07/ZVcciN37eg.json", // Second animation URL
  ];

  const location = useLocation();
  const patientId = location.state?.patientId;
  const claimId = location.state?.claimId;  // Also retrieve claimId from state

  useEffect(() => {
    // Ensure both patientId and claimId are available
    if (!patientId || !claimId) {
      setError("Patient ID or Claim ID is missing.");
      console.error("Patient ID or Claim ID is missing");
      return;
    }

    const fetchData = async () => {
      const url1 = `${API_ENDPOINTS.PYTHON_SERVICE}/analyze_documents/${patientId}/${claimId}`;
      const url2 = `${API_ENDPOINTS.PYTHON_SERVICE}/save_response`;

      console.log("Making POST request to URL 1:", url1);

      try {
        const response1 = await fetch(url1, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ patientId, claimId }),  // Send both patientId and claimId
        });

        if (response1.ok) {
          const data1 = await response1.json();
          console.log("API call 1 successful:", data1);
          setSteps((prevSteps) => {
            const newSteps = [...prevSteps];
            newSteps[0] = true;
            newSteps[1] = true; // Mark the second step as completed
            setProgress(45); // Move to 45% after the first two steps
            return newSteps;
          });

          console.log("Making POST request to URL 2:", url2);

          // const response2 = await fetch(url2, {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   body: JSON.stringify(data1),
            
          // });

          const response2 = await fetch(`${url2}?patient_id=${patientId}&claim_id=${claimId}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data1),  // Only `data1` is in the body
          });
          
          if (response2.ok) {
            const data2 = await response2.json();
            console.log("API call 2 successful:", data2);
            handleApiResponse(data2);
          } else {
            console.error("Failed to process request 2:", response2.statusText);
            const errorData = await response2.json();
            console.error("Error details:", errorData);
            setError("Error processing request 2.");
          }
        } else {
          console.error("Failed to process request 1:", response1.statusText);
          const errorData = await response1.json();
          console.error("Error details:", errorData);
          setError("Error processing request 1.");
        }
      } catch (error) {
        console.error("Error in fetching data:", error);
        setError("An error occurred while fetching data.");
      }
    };

    fetchData();
  }, [patientId, claimId]);  // Add claimId as a dependency

  const handleApiResponse = (data) => {
    // Process the API response data here
    setResponseData(data);

    // Example processing: Log the response data
    console.log("Processing response data:", data);

    // Start the animation steps
    startAnimation();
  };

  const startAnimation = () => {
    let stepIndex = 2; // Start from 2 since the first two steps are already completed
    const interval = setInterval(() => {
      setSteps((prevSteps) => {
        const newSteps = [...prevSteps];
        newSteps[stepIndex] = true;
        // Update progress based on step index
        if (stepIndex === 2) {
          setProgress(60); // 60% after third step
        } else if (stepIndex === 3) {
          setProgress(80); // 80% after fourth step
        } else if (stepIndex === 4) {
          setProgress(100); // 100% after fifth step
        }
        stepIndex += 1;
        if (stepIndex === newSteps.length) {
          clearInterval(interval);
          setTimeout(() => {

            // Save patientId and claimId in session storage before navigating
            sessionStorage.setItem("patientId", patientId);
            sessionStorage.setItem("claimId", claimId);
            
            navigate("/claim-details", { state: { patientId, claimId, responseData } });
          }, 500);
        }
        return newSteps;
      });
    }, 1000);
  };

  useEffect(() => {
    const toggleInterval = setInterval(() => {
      setCurrentAnimationIndex((prevIndex) => (prevIndex + 1) % animations.length);
    }, 5000); // Switch animation every 5 seconds

    return () => clearInterval(toggleInterval); // Clear interval on component unmount
  }, [animations.length]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    path: animations[currentAnimationIndex], // Use the current animation URL
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="animation-page">
      <header>
        <h1>Initiating claim review</h1>
      </header>

      {/* Display error message if there's an error */}
      {error && <div className="error-message">{error}</div>}

      <div className="lottie-container">
        <Lottie key={currentAnimationIndex} options={defaultOptions} height={100} width={100} />
      </div>

      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>

      <ul className="animation-list">
        <li>
          <span>Verifying member eligibility.....</span>
          <span className={`checkmark ${steps[0] ? "checked" : ""}`}>✔</span>
        </li>
        <li>
          <span>Analysing policy details.....</span>
          <span className={`checkmark ${steps[1] ? "checked" : ""}`}>✔</span>
        </li>
        <li>
          <span>Comparing with Standard Treatment Guidelines...</span>
          <span className={`checkmark ${steps[2] ? "checked" : ""}`}>✔</span>
        </li>
        <li>
          <span>Reviewing Bills, Tariffs, & MOUs...</span>
          <span className={`checkmark ${steps[3] ? "checked" : ""}`}>✔</span>
        </li>
        <li>
          <span>Detecting Potential Fraud...</span>
          <span className={`checkmark ${steps[4] ? "checked" : ""}`}>✔</span>
        </li>
      </ul>
    </div>
  );
}

export default AnimationPage;
