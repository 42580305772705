// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Header from './Header';
// import './TariffDetails.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
// import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';
// import { marked } from 'marked';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function TariffDetails() {
//   const { documentId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [pdfData, setPdfData] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const otherPdfUrl = `http://localhost:5000/get_other_pdf?docId=tariff_document&cb=${new Date().getTime()}`; // Updated to the other PDF API URL

//   useEffect(() => {
//     const fetchResponseText = async () => {
//       const url = 'http://localhost:5000/get_processed_data'; // Update to your FastAPI server URL
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json'
//           }
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setResponseText(data.response_text[documentId]);
//         } else {
//           console.error('Failed to fetch response text', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching response text:', error);
//       }
//     };

//     const fetchPdfData = async () => {
//       try {
//         const response = await fetch(otherPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf'
//           }
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setPdfData(url);
//         } else {
//           console.error('Failed to fetch PDF data', await response.text());
//         }
//       } catch (error) {
//         console.error('Error fetching PDF data:', error);
//       }
//     };

//     fetchResponseText();
//     fetchPdfData();
//   }, [documentId]);

//   function onDocumentLoadSuccessRight({ numPages }) {
//     setNumPagesRight(numPages);
//   }

//   function toTitleCase(str) {
//     return str
//       .replace(/_/g, ' ')
//       .replace(/\w\S*/g, function(txt) {
//         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//       });
//   }

//   if (!responseText || !pdfData) {
//     return <div>Loading...</div>;
//   }

//   const titleCaseDocumentId = toTitleCase(documentId);

//   return (
//     <div className="tariff-details-page">
//       <Header docId={documentId} />
//       <div className="content">
//         {/* <div className="response-container">
//           {responseText.map((text, index) => (
//             <div key={index} className="response-item">
//               <p><strong>Category:</strong> {titleCaseDocumentId}</p>
//               <p><strong>Details:</strong></p>
//               <div dangerouslySetInnerHTML={{ __html: marked(text) }} />
//             </div>
//           ))}
//         </div> */}
//         <div className="pdf-comparison">
//           <div className="pdf-container">
//             <h3>{titleCaseDocumentId}</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={pdfData}
//                 onLoadSuccess={onDocumentLoadSuccessRight}
//               >
//                 {Array.from(new Array(numPagesRight), (el, index) => (
//                   <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//           <div className="calculation-container">
//             <h3>Proportionate Deduction Calculation</h3>
//             <div className="calculation-table">
//               <div className="calculation-row">
//                 <div className="label">Claimed Amount</div>
//                 <div className="value">₹ 19,500</div>
//               </div>
//               <div className="calculation-row">
//                 <div className="label">Diagnostics</div>
//                 <div className="value">₹ 3,000</div>
//               </div>
//               <div className="calculation-row">
//                 <div className="label">Pharmacy</div>
//                 <div className="value">₹ 2,000</div>
//               </div>
//               <div className="calculation-row">
//                 <div className="label">Net Amount For Prop Deduction</div>
//                 <div className="value">₹ 15,500</div>
//               </div>
//               <div className="calculation-row highlight">
//                 <div className="label">28% Prop Deduction</div>
//                 <div className="value">₹ 4,060</div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="button-group">
//           <button className="secondary-button">Overrule Discrepancy</button>
//           <button>Confirm Discrepancy</button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default TariffDetails;









// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Header from './Header';
// import './TariffDetails.css';
// import { Document, Page, pdfjs } from 'react-pdf';
// import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
// import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';

// // Set the workerSrc property to use the locally hosted worker script
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

// function TariffDetails() {
//   const { documentId } = useParams();
//   const [responseText, setResponseText] = useState(null);
//   const [leftPdfData, setLeftPdfData] = useState(null);
//   const [rightPdfData, setRightPdfData] = useState(null);
//   const [numPagesLeft, setNumPagesLeft] = useState(null);
//   const [numPagesRight, setNumPagesRight] = useState(null);
//   const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36);
//   const [discrepancyAmounts, setDiscrepancyAmounts] = useState({});
//   const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({});
//   const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0);
//   const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false);
//   const [actionToConfirm, setActionToConfirm] = useState(null);
//   const [actionHistory, setActionHistory] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [pdfError, setPdfError] = useState(null);

//   const settlementPdfUrl = `http://localhost:5000/get_settlement_pdf?cb=${new Date().getTime()}`;
//   const iceSettlementPdfUrl = `http://localhost:5000/ice_settlement?cb=${new Date().getTime()}`;

//   useEffect(() => {
//     const fetchResponseText = async () => {
//       const url = 'http://localhost:5000/get_processed_data';
//       console.log(`Fetching response text from: ${url}`);
//       try {
//         const response = await fetch(url, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           console.log('Response text data received:', data);
//           if (data.response_text && data.response_text[documentId]) {
//             setResponseText(data.response_text[documentId]);
//             console.log('Set responseText:', data.response_text[documentId]);
//           } else {
//             console.warn(`No response text found for documentId: ${documentId}`);
//             setResponseText('No additional details available for this document.');
//           }
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to fetch response text:', errorText);
//           setResponseText('Failed to load details. Please try again later.');
//         }
//       } catch (error) {
//         console.error('Error fetching response text:', error);
//         setResponseText('Failed to load details. Please try again later.');
//       }
//     };

//     const fetchLeftPdfData = async () => {
//       console.log(`Fetching left PDF data from: ${settlementPdfUrl}`);
//       try {
//         const response = await fetch(settlementPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf',
//           },
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setLeftPdfData(url);
//           console.log('Set leftPdfData URL:', url);
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to fetch left PDF data:', errorText);
//         }
//       } catch (error) {
//         console.error('Error fetching left PDF data:', error);
//       }
//     };

//     const fetchRightPdfData = async () => {
//       console.log(`Fetching right PDF data from: ${iceSettlementPdfUrl}`);
//       try {
//         const response = await fetch(iceSettlementPdfUrl, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/pdf',
//           },
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setRightPdfData(url);
//           console.log('Set rightPdfData URL:', url);
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to fetch right PDF data:', errorText);
//         }
//       } catch (error) {
//         console.error('Error fetching right PDF data:', error);
//       }
//     };

//     fetchResponseText();
//     fetchLeftPdfData();
//     fetchRightPdfData().finally(() => setLoading(false)); // Ensure loading is set to false after data is fetched
//   }, [documentId]);

//   function onDocumentLoadSuccessLeft({ numPages }) {
//     setNumPagesLeft(numPages);
//     console.log('Left PDF document loaded successfully with numPages:', numPages);
//   }

//   function onDocumentLoadSuccessRight({ numPages }) {
//     setNumPagesRight(numPages);
//     console.log('Right PDF document loaded successfully with numPages:', numPages);
//   }

//   function onDocumentLoadError(error) {
//     console.error('Error loading PDF document:', error);
//     setPdfError('Failed to load PDF file. Please check the file format or try again later.');
//   }

//   const handleOverruleDiscrepancy = () => {
//     if (overruledDiscrepancies[currentDiscrepancyIndex]) {
//       return;
//     }
//     setActionToConfirm('overrule');
//     setIsConfirmBoxVisible(true);
//   };

//   const handleConfirmDiscrepancy = () => {
//     if (actionToConfirm === 'overrule') {
//       if (!overruledDiscrepancies[currentDiscrepancyIndex]) {
//         setEstEligibleAmount(prevAmount => prevAmount + discrepancyAmounts[currentDiscrepancyIndex]);
//         setOverruledDiscrepancies(prevState => ({
//           ...prevState,
//           [currentDiscrepancyIndex]: true
//         }));
//         setActionHistory(prevHistory => [
//           ...prevHistory,
//           { action: 'overrule', index: currentDiscrepancyIndex, amount: discrepancyAmounts[currentDiscrepancyIndex] }
//         ]);
//       }
//     }
//     setIsConfirmBoxVisible(false);
//     setActionToConfirm(null);
//   };

//   const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
//     console.log('handleDiscrepancyChange called with:', newDiscrepancyAmount, discrepancyIndex);
//     setActionHistory(prevHistory => [
//       ...prevHistory,
//       { action: 'update', index: discrepancyIndex, amount: discrepancyAmounts[discrepancyIndex] || 0 }
//     ]);
//     setDiscrepancyAmounts(prevState => ({
//       ...prevState,
//       [discrepancyIndex]: newDiscrepancyAmount
//     }));
//     setCurrentDiscrepancyIndex(discrepancyIndex);
//   };

//   const revertLastAction = async () => {
//     console.log('revertLastAction called');
//     if (actionHistory.length === 0) {
//       console.log('No actions to revert');
//       return;
//     }

//     const lastAction = actionHistory[actionHistory.length - 1];
//     console.log('Last action:', lastAction);

//     if (lastAction.action === 'overrule') {
//       setEstEligibleAmount(prevAmount => prevAmount - lastAction.amount);
//       setOverruledDiscrepancies(prevState => {
//         const newState = { ...prevState };
//         delete newState[lastAction.index];
//         return newState;
//       });
//     } else if (lastAction.action === 'update') {
//       const revertedAmount = lastAction.amount;
//       const discrepancyKey = documentId === 'stg_document' ? 'alignment_percentage' : lastAction.index;

//       try {
//         const response = await fetch('http://localhost:5000/update-discrepancy', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             [discrepancyKey]: revertedAmount
//           })
//         });

//         if (response.ok) {
//           console.log("Discrepancy reverted successfully on the server.");
//           setDiscrepancyAmounts(prevState => ({
//             ...prevState,
//             [lastAction.index]: revertedAmount
//           }));
//           setCurrentDiscrepancyIndex(lastAction.index);
//         } else {
//           const errorText = await response.text();
//           console.error("Failed to revert discrepancy on the server:", errorText);
//         }
//       } catch (error) {
//         console.error("Error reverting discrepancy:", error);
//       }
//     }
//     setActionHistory(prevHistory => prevHistory.slice(0, -1));
//   };

//   function toTitleCase(str) {
//     return str
//       .replace(/_/g, ' ')
//       .replace(/\w\S*/g, function(txt) {
//         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//       });
//   }

//   if (loading) {
//     console.log('Loading data...');
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     console.error('Error encountered:', error);
//     return <div>Error: {error}</div>;
//   }

//   if (pdfError) {
//     console.error('PDF Error encountered:', pdfError);
//     return <div>Error: {pdfError}</div>;
//   }

//   if (!responseText || !leftPdfData || !rightPdfData) {
//     console.log('Missing data - responseText:', responseText, 'leftPdfData:', leftPdfData, 'rightPdfData:', rightPdfData);
//     return <div>Loading...</div>;
//   }

//   const titleCaseDocumentId = toTitleCase(documentId);
//   console.log('Rendering page with titleCaseDocumentId:', titleCaseDocumentId);

//   return (
//     <div className="tariff-details-page">
//       <Header docId={documentId} />
//       <div className="button-group">
//         <div className="revert-button-container">
//           <button onClick={revertLastAction} className="revert-button">Revert Last Action</button>
//         </div>
//         <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
//           Overrule Discrepancy
//         </button>
//         <div className="confirm-discrepancy-btn">
//           <button onClick={handleConfirmDiscrepancy}>
//             Confirm Discrepancy
//           </button>
//           {isConfirmBoxVisible && (
//             <div className="floating-confirm-box">
//               <p>Are you sure you want to confirm this discrepancy?</p>
//             </div>
//           )}
//         </div>
//       </div>
//       <div className="content">
//         <div className="pdf-comparison">
//           <div className="pdf-container">
//             <h3>Settlement Document</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={leftPdfData}
//                 onLoadSuccess={onDocumentLoadSuccessLeft}
//                 onLoadError={onDocumentLoadError}
//               >
//                 {Array.from(new Array(numPagesLeft), (el, index) => (
//                   <Page key={`page_left_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//           <div className="pdf-container">
//             <h3>ICE Settlement Document</h3>
//             <div className="pdf-viewer">
//               <Document
//                 file={rightPdfData}
//                 onLoadSuccess={onDocumentLoadSuccessRight}
//                 onLoadError={onDocumentLoadError}
//               >
//                 {Array.from(new Array(numPagesRight), (el, index) => (
//                   <Page key={`page_right_${index + 1}`} pageNumber={index + 1} />
//                 ))}
//               </Document>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default TariffDetails;





import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Header from './Header';
import './TariffDetails.css';
import { Document, Page, pdfjs } from 'react-pdf';
import '../../node_modules/react-pdf/dist/esm/Page/AnnotationLayer.css';
import '../../node_modules/react-pdf/dist/esm/Page/TextLayer.css';
import { API_ENDPOINTS } from '../config';

// Set the workerSrc property to use the locally hosted worker script
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.mjs`;

function TariffDetails() {
  const { documentId } = useParams();
  const [responseText, setResponseText] = useState(null);
  const [leftPdfData, setLeftPdfData] = useState(null);
  const [rightPdfData, setRightPdfData] = useState(null);
  const [numPagesLeft, setNumPagesLeft] = useState(null);
  const [numPagesRight, setNumPagesRight] = useState(null);
  const [estEligibleAmount, setEstEligibleAmount] = useState(29276.36);
  const [discrepancyAmounts, setDiscrepancyAmounts] = useState({});
  const [overruledDiscrepancies, setOverruledDiscrepancies] = useState({});
  const [currentDiscrepancyIndex, setCurrentDiscrepancyIndex] = useState(0);
  const [isConfirmBoxVisible, setIsConfirmBoxVisible] = useState(false);
  const [actionToConfirm, setActionToConfirm] = useState(null);
  const [actionHistory, setActionHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfError, setPdfError] = useState(null);


  // Retrieve patientId and claimId from location.state or sessionStorage
  const location = useLocation();
  // const passedPatientId = location.state?.patientId || sessionStorage.getItem("patientId");
  // const passedClaimId = location.state?.claimId || sessionStorage.getItem("claimId");
  const passedPatientId = "ICE-ACCCA7FA"
  const passedClaimId = "CLAIM-5C09593C"

  const settlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/get_settlement_pdf?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;
  const iceSettlementPdfUrl = `${API_ENDPOINTS.PYTHON_SERVICE}/ice_settlement?patient_id=${passedPatientId}&claim_id=${passedClaimId}&cb=${new Date().getTime()}`;

  useEffect(() => {
    if (!documentId) {
      setError('Document ID is undefined.');
      setLoading(false);
      return;
    }

    const fetchResponseText = async () => {
      const url = `${API_ENDPOINTS.PYTHON_SERVICE}/get_processed_data?patient_id=${passedPatientId}&claim_id=${passedClaimId}`;
      console.log(`Fetching response text from: ${url}`);
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Response text data received:', data);
          if (data.response_text && data.response_text[documentId]) {
            setResponseText(data.response_text[documentId]);
            console.log('Set responseText:', data.response_text[documentId]);
          } else {
            console.warn(`No response text found for documentId: ${documentId}`);
            setResponseText('No additional details available for this document.');
          }
        } else {
          const errorText = await response.text();
          console.error('Failed to fetch response text:', errorText);
          setResponseText('Failed to load details. Please try again later.');
        }
      } catch (error) {
        console.error('Error fetching response text:', error);
        setResponseText('Failed to load details. Please try again later.');
      }
    };

    const fetchLeftPdfData = async () => {
      console.log(`Fetching left PDF data from: ${settlementPdfUrl}`);
      try {
        const response = await fetch(settlementPdfUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setLeftPdfData(url);
          console.log('Set leftPdfData URL:', url);
        } else {
          const errorText = await response.text();
          console.error('Failed to fetch left PDF data:', errorText);
          setPdfError('Failed to load Settlement PDF.');
        }
      } catch (error) {
        console.error('Error fetching left PDF data:', error);
        setPdfError('Failed to load Settlement PDF.');
      }
    };

    const fetchRightPdfData = async () => {
      console.log(`Fetching right PDF data from: ${iceSettlementPdfUrl}`);
      try {
        const response = await fetch(iceSettlementPdfUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
          },
        });

        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setRightPdfData(url);
          console.log('Set rightPdfData URL:', url);
        } else {
          const errorText = await response.text();
          console.error('Failed to fetch right PDF data:', errorText);
          setPdfError('Failed to load ICE Settlement PDF.');
        }
      } catch (error) {
        console.error('Error fetching right PDF data:', error);
        setPdfError('Failed to load ICE Settlement PDF.');
      }
    };

    fetchResponseText();
    fetchLeftPdfData();
    fetchRightPdfData().finally(() => setLoading(false)); // Ensure loading is set to false after data is fetched
  }, [documentId, settlementPdfUrl, iceSettlementPdfUrl]);

  function onDocumentLoadSuccessLeft({ numPages }) {
    setNumPagesLeft(numPages);
    console.log('Left PDF document loaded successfully with numPages:', numPages);
  }

  function onDocumentLoadSuccessRight({ numPages }) {
    setNumPagesRight(numPages);
    console.log('Right PDF document loaded successfully with numPages:', numPages);
  }

  function onDocumentLoadError(error) {
    console.error('Error loading PDF document:', error);
    setPdfError('Failed to load PDF file. Please check the file format or try again later.');
  }

  const handleOverruleDiscrepancy = () => {
    if (overruledDiscrepancies[currentDiscrepancyIndex]) {
      return;
    }
    setActionToConfirm('overrule');
    setIsConfirmBoxVisible(true);
  };

  const handleConfirmDiscrepancy = () => {
    if (actionToConfirm === 'overrule') {
      if (!overruledDiscrepancies[currentDiscrepancyIndex]) {
        setEstEligibleAmount(prevAmount => prevAmount + discrepancyAmounts[currentDiscrepancyIndex]);
        setOverruledDiscrepancies(prevState => ({
          ...prevState,
          [currentDiscrepancyIndex]: true
        }));
        setActionHistory(prevHistory => [
          ...prevHistory,
          { action: 'overrule', index: currentDiscrepancyIndex, amount: discrepancyAmounts[currentDiscrepancyIndex] }
        ]);
      }
    }
    setIsConfirmBoxVisible(false);
    setActionToConfirm(null);
  };

  const handleDiscrepancyChange = (newDiscrepancyAmount, discrepancyIndex) => {
    console.log('handleDiscrepancyChange called with:', newDiscrepancyAmount, discrepancyIndex);
    setActionHistory(prevHistory => [
      ...prevHistory,
      { action: 'update', index: discrepancyIndex, amount: discrepancyAmounts[discrepancyIndex] || 0 }
    ]);
    setDiscrepancyAmounts(prevState => ({
      ...prevState,
      [discrepancyIndex]: newDiscrepancyAmount
    }));
    setCurrentDiscrepancyIndex(discrepancyIndex);
  };

  const revertLastAction = async () => {
    console.log('revertLastAction called');
    if (actionHistory.length === 0) {
      console.log('No actions to revert');
      return;
    }

    const lastAction = actionHistory[actionHistory.length - 1];
    console.log('Last action:', lastAction);

    if (lastAction.action === 'overrule') {
      setEstEligibleAmount(prevAmount => prevAmount - lastAction.amount);
      setOverruledDiscrepancies(prevState => {
        const newState = { ...prevState };
        delete newState[lastAction.index];
        return newState;
      });
    } else if (lastAction.action === 'update') {
      const revertedAmount = lastAction.amount;
      const discrepancyKey = documentId === 'stg_document' ? 'alignment_percentage' : lastAction.index;

      try {
        const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/update-discrepancy?patient_id=${passedPatientId}&claim_id=${passedClaimId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            [discrepancyKey]: revertedAmount
          })
        });

        if (response.ok) {
          console.log("Discrepancy reverted successfully on the server.");
          setDiscrepancyAmounts(prevState => ({
            ...prevState,
            [lastAction.index]: revertedAmount
          }));
          setCurrentDiscrepancyIndex(lastAction.index);
        } else {
          const errorText = await response.text();
          console.error("Failed to revert discrepancy on the server:", errorText);
        }
      } catch (error) {
        console.error("Error reverting discrepancy:", error);
      }
    }
    setActionHistory(prevHistory => prevHistory.slice(0, -1));
  };

  function toTitleCase(str) {
    return str
      .replace(/_/g, ' ')
      .replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  }

  if (loading) {
    console.log('Loading data...');
    return <div>Loading...</div>;
  }

  if (error) {
    console.error('Error encountered:', error);
    return <div>Error: {error}</div>;
  }

  if (pdfError) {
    console.error('PDF Error encountered:', pdfError);
    return <div>Error: {pdfError}</div>;
  }

  if (!responseText || !leftPdfData || !rightPdfData) {
    console.log('Missing data - responseText:', responseText, 'leftPdfData:', leftPdfData, 'rightPdfData:', rightPdfData);
    return <div>Loading...</div>;
  }

  const titleCaseDocumentId = toTitleCase(documentId);
  console.log('Rendering page with titleCaseDocumentId:', titleCaseDocumentId);

  return (
    <div className="tariff-details-page">
      <Header docId={documentId} />
      <div className="button-group">
        <div className="revert-button-container">
          <button onClick={revertLastAction} className="revert-button">Revert Last Action</button>
        </div>
        <button className="secondary-button" onClick={handleOverruleDiscrepancy}>
          Overrule Discrepancy
        </button>
        <div className="confirm-discrepancy-btn">
          <button onClick={handleConfirmDiscrepancy}>
            Confirm Discrepancy
          </button>
          {isConfirmBoxVisible && (
            <div className="floating-confirm-box">
              <p>Are you sure you want to confirm this discrepancy?</p>
            </div>
          )}
        </div>
      </div>
      <div className="content">
        <div className="pdf-comparison">
          <div className="pdf-container">
            <h3>Settlement Document</h3>
            <div className="pdf-viewer">
              <Document
                file={leftPdfData}
                onLoadSuccess={onDocumentLoadSuccessLeft}
                onLoadError={onDocumentLoadError}
              >
                {Array.from(new Array(numPagesLeft), (el, index) => (
                  <Page key={`page_left_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          </div>
          <div className="pdf-container">
            <h3>ICE Settlement Document</h3>
            <div className="pdf-viewer">
              <Document
                file={rightPdfData}
                onLoadSuccess={onDocumentLoadSuccessRight}
                onLoadError={onDocumentLoadError}
              >
                {Array.from(new Array(numPagesRight), (el, index) => (
                  <Page key={`page_right_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TariffDetails;
