// import React, { useState } from 'react';
// import fileIcon from './file-icon.png'; // Ensure this path is correct
// import uploadIcon from '../icons/upload-icon.png'
// import './UploadSection.css';
// import { API_ENDPOINTS } from '../config';

// function UploadSection({ title, formats, onFileUpload, disabled }) {
//   const [files, setFiles] = useState([]);
//   const [progress, setProgress] = useState({});
//   const [uploading, setUploading] = useState({});
//   const [isDragging, setIsDragging] = useState(false);

//   const handleFileChange = async (event) => {
//     const selectedFiles = Array.from(event.target.files);
//     setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);

//     selectedFiles.forEach(file => uploadFile(file));
//   };

//   const handleDragOver = (event) => {
//     event.preventDefault();
//     setIsDragging(true);
//   };

//   const handleDragLeave = () => {
//     setIsDragging(false);
//   };

//   const handleDrop = (event) => {
//     event.preventDefault();
//     const selectedFiles = Array.from(event.dataTransfer.files);
//     setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);

//     selectedFiles.forEach(file => uploadFile(file));
//     setIsDragging(false);
//   };

//   const uploadFile = async (file) => {
//     const formattedTitle = title.replace(/ /g, '_').toLowerCase();
//     setUploading((prevUploading) => ({ ...prevUploading, [file.name]: true }));
//     setProgress((prevProgress) => ({ ...prevProgress, [file.name]: 0 }));

//     const formData = new FormData();
//     formData.append('files', file);
//     formData.append('title', title);

//     // Simulate upload progress
//     const uploadDuration = 2000; // 2 seconds
//     const intervalDuration = 100; // 0.1 second
//     const increment = 100 / (uploadDuration / intervalDuration);

//     let currentProgress = 0;

//     const interval = setInterval(() => {
//       currentProgress += increment;
//       setProgress((prevProgress) => ({ ...prevProgress, [file.name]: currentProgress }));

//       if (currentProgress >= 100) {
//         clearInterval(interval);
//         setProgress((prevProgress) => ({ ...prevProgress, [file.name]: 100 }));
//         setUploading((prevUploading) => ({ ...prevUploading, [file.name]: false }));

//         // Hide progress bar after 2 seconds
//         setTimeout(() => {
//           setProgress((prevProgress) => {
//             const newProgress = { ...prevProgress };
//             delete newProgress[file.name];
//             return newProgress;
//           });
//         }, 2000);
//       }
//     }, intervalDuration);

//     // Actual upload request
//     try {
//       const response = await fetch(`${API_ENDPOINTS.EXPRESS_SERVICE}/upload-temp`, {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         console.error('Upload failed');
//       } else {
//         onFileUpload(title, [file]);
//       }
//     } catch (error) {
//       console.error('Upload error', error);
//     }
//   };

//   const handleFileRemove = async (fileToRemove) => {
//     const formattedTitle = title.replace(/ /g, '_').toLowerCase();
//     const filePath = `${formattedTitle}/${fileToRemove.name}`;
//     console.log(`Deleting file: ${filePath}`);

//     // Remove file from the UI
//     setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));

//     // Send request to the server to delete the file
//     try {
//       const response = await fetch(`${API_ENDPOINTS.EXPRESS_SERVICE}/delete-file`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ filename: filePath }),
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         console.error('File deletion failed', errorText);
//       } else {
//         console.log('File deleted successfully');
//       }
//     } catch (error) {
//       console.error('File deletion error', error);
//     }
//   };

//   return (
//     <div className={`upload-section ${disabled ? 'disabled' : ''}`}>
//       <h3>{title}</h3>
//       <div
//         className={`upload-box ${Object.values(uploading).some((isUploading) => isUploading) ? 'uploading' : ''} ${isDragging ? 'dragging' : ''}`}
//         onDragOver={handleDragOver}
//         onDragLeave={handleDragLeave}
//         onDrop={handleDrop}
//       >
//         <label className="custom-file-label">
//           <input
//             type="file"
//             accept={formats}
//             multiple
//             onChange={handleFileChange}
//             disabled={disabled}
//             className="custom-file-input"
//           />
//           <div className="upload-text">
//             <img src={uploadIcon} alt="Upload Icon" className="upload-icon" />
//             <p>Drag and drop here<br />or <span className="browse-link">browse</span></p>
//           </div>
//         </label>
//         <div className="file-info">
//           {files.length > 0 && (
//             <div className="file-preview">
//               {files.map((file, index) => (
//                 <div key={index} className="file-item">
//                   <div className="file-details">
//                     <img src={fileIcon} alt="File Icon" className="file-icon" />
//                     <span className="file-name">{file.name}</span>
//                     {uploading[file.name] && <span className="file-progress">{Math.round(progress[file.name] || 0)}%</span>}
//                   </div>
//                   <button className="cancel-button" onClick={() => handleFileRemove(file)}>X</button>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//         {Object.keys(progress).length > 0 && (
//           <div className="progress-bar">
//             <div className="progress" style={{ width: `${Math.max(...Object.values(progress))}%` }}></div>
//           </div>
//         )}
//       </div>
//       <p>Supported formats: {formats}</p>
//     </div>
//   );
// }

// export default UploadSection;



import React, { useState } from 'react';
import fileIcon from './file-icon.png'; // Ensure this path is correct
import uploadIcon from '../icons/upload-icon.png';
import './UploadSection.css';
import { API_ENDPOINTS } from '../config';

function UploadSection({ title, formats, onFileUpload, disabled, patientId, claimId }) {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [uploading, setUploading] = useState({});
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);

    selectedFiles.forEach((file) => onFileUpload(title, [file]));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const selectedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);

    selectedFiles.forEach((file) => onFileUpload(title, [file]));
    setIsDragging(false);
  };

  const handleFileRemove = async (fileToRemove) => {
    if (!patientId || !claimId) {
      alert('Cannot delete the file because patient ID or claim ID is missing.');
      return;
    }
    const formattedTitle = title.replace(/ /g, '_').toLowerCase();
    const filePath = `${patientId}/${claimId}/${formattedTitle}/${fileToRemove.name}`;
  
    // Remove file from UI
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  
    // Send request to delete the file from S3
    try {
      const response = await fetch(`${API_ENDPOINTS.PYTHON_SERVICE}/delete-file`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ filePath }), // Ensure proper JSON format
      });
  
      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('File deletion failed:', errorMessage);
        alert('Failed to delete the file.');
      } else {
        console.log('File deleted successfully');
      }
    } catch (error) {
      console.error('File deletion error:', error);
      alert('An error occurred while deleting the file.');
    }
  };
  

  return (
    <div className={`upload-section ${disabled ? 'disabled' : ''}`}>
      <h3>{title}</h3>
      <div
        className={`upload-box ${Object.values(uploading).some((isUploading) => isUploading) ? 'uploading' : ''} ${
          isDragging ? 'dragging' : ''
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <label className="custom-file-label">
          <input
            type="file"
            accept={formats}
            multiple
            onChange={handleFileChange}
            disabled={disabled}
            className="custom-file-input"
          />
          <div className="upload-text">
            <img src={uploadIcon} alt="Upload Icon" className="upload-icon" />
            <p>
              Drag and drop here<br />or <span className="browse-link">browse</span>
            </p>
          </div>
        </label>
        <div className="file-info">
          {files.length > 0 && (
            <div className="file-preview">
              {files.map((file, index) => (
                <div key={index} className="file-item">
                  <div className="file-details">
                    <img src={fileIcon} alt="File Icon" className="file-icon" />
                    <span className="file-name">{file.name}</span>
                  </div>
                  <button className="cancel-button" onClick={() => handleFileRemove(file)}>
                    X
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <p>Supported formats: {formats}</p>
    </div>
  );
}

export default UploadSection;
